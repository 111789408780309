import { format } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';

const locales = { enGB, 'fr-FR': fr };

export const formatDateMixin = {
    methods: {
        formatDate(date, formatDate = 'dd/MM/yyyy') {
            if (date) {
                return format(new Date(date), formatDate, {
                    locale: locales[navigator.language],
                });
            } else {
                return '-';
            }
        },
    },
};
